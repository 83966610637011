export const classificationsCategories = [
    { type: 'video', classification: 'documentary', category: 'animals' },
    { type: 'video', classification: 'documentary', category: 'life' },
    { type: 'video', classification: 'documentary', category: 'finance' },
    { type: 'video', classification: 'documentary', category: 'war' },
    { type: 'video', classification: 'documentary', category: 'history' },
    { type: 'video', classification: 'documentary', category: 'horror' },
    { type: 'video', classification: 'documentary', category: 'musicals' },
    { type: 'video', classification: 'documentary', category: 'science' },
    { type: 'video', classification: 'documentary', category: 'sports' },
    { type: 'video', classification: 'documentary', category: 'westerns' },
    { type: 'video', classification: 'movies', category: 'animalspets' },
    { type: 'video', classification: 'movies', category: 'animated' },
    { type: 'video', classification: 'movies', category: 'art' },
    { type: 'video', classification: 'movies', category: 'children' },
    { type: 'video', classification: 'movies', category: 'comedy' },
    { type: 'video', classification: 'movies', category: 'documentary' },
    { type: 'video', classification: 'movies', category: 'drama' },
    { type: 'video', classification: 'movies', category: 'fantasy' },
    { type: 'video', classification: 'movies', category: 'history' },
    { type: 'video', classification: 'movies', category: 'horror' },
    { type: 'video', classification: 'movies', category: 'musicals' },
    { type: 'video', classification: 'movies', category: 'romance' },
    { type: 'video', classification: 'movies', category: 'sciencefiction' },
    { type: 'video', classification: 'movies', category: 'sports' },
    { type: 'video', classification: 'movies', category: 'theater' },
    { type: 'video', classification: 'movies', category: 'thrillers' },
    { type: 'video', classification: 'movies', category: 'westerns' },
    { type: 'video', classification: 'anime', category: 'actionadventure' },
    { type: 'video', classification: 'anime', category: 'children' },
    { type: 'video', classification: 'anime', category: 'comedy' },
    { type: 'video', classification: 'anime', category: 'drama' },
    { type: 'video', classification: 'anime', category: 'fantasy' },
    { type: 'video', classification: 'anime', category: 'horror' },
    { type: 'video', classification: 'anime', category: 'mecha' },
    { type: 'video', classification: 'anime', category: 'romance' },
    { type: 'video', classification: 'anime', category: 'sciencefiction' },
    { type: 'video', classification: 'anime', category: 'sliceoflife' },
    { type: 'video', classification: 'series', category: 'actionadventure' },
    { type: 'video', classification: 'series', category: 'animalspets' },
    { type: 'video', classification: 'series', category: 'animated' },
    { type: 'video', classification: 'series', category: 'art' },
    { type: 'video', classification: 'series', category: 'children' },
    { type: 'video', classification: 'series', category: 'comedy' },
    { type: 'video', classification: 'series', category: 'cooking' },
    { type: 'video', classification: 'series', category: 'documentary' },
    { type: 'video', classification: 'series', category: 'drama' },
    { type: 'video', classification: 'series', category: 'fantasy' },
    { type: 'video', classification: 'series', category: 'health' },
    { type: 'video', classification: 'series', category: 'history' },
    { type: 'video', classification: 'series', category: 'horror' },
    { type: 'video', classification: 'series', category: 'musicals' },
    { type: 'video', classification: 'series', category: 'realitytv' },
    { type: 'video', classification: 'series', category: 'romance' },
    { type: 'video', classification: 'series', category: 'sciencefiction' },
    { type: 'video', classification: 'series', category: 'sports' },
    { type: 'video', classification: 'series', category: 'talkshows' },
    { type: 'video', classification: 'series', category: 'travel' },
    { type: 'video', classification: 'series', category: 'westerns' },
    { type: 'video', classification: 'caughtlive', category: 'animalspets' },
    { type: 'video', classification: 'caughtlive', category: 'art' },
    { type: 'video', classification: 'caughtlive', category: 'autos' },
    { type: 'video', classification: 'caughtlive', category: 'children' },
    { type: 'video', classification: 'caughtlive', category: 'comedy' },
    { type: 'video', classification: 'caughtlive', category: 'concerts' },
    { type: 'video', classification: 'caughtlive', category: 'concerts' },
    { type: 'video', classification: 'caughtlive', category: 'cookingfood' },
    { type: 'video', classification: 'caughtlive', category: 'drama' },
    { type: 'video', classification: 'caughtlive', category: 'fashion' },
    { type: 'video', classification: 'caughtlive', category: 'gaming' },
    { type: 'video', classification: 'caughtlive', category: 'health' },
    { type: 'video', classification: 'caughtlive', category: 'politicsgovernment' },
    { type: 'video', classification: 'caughtlive', category: 'science' },
    { type: 'video', classification: 'caughtlive', category: 'sports' },
    { type: 'video', classification: 'caughtlive', category: 'stunts' },
    { type: 'video', classification: 'caughtlive', category: 'sciencetechnology' },
    { type: 'video', classification: 'caughtlive', category: 'theater' },
    { type: 'video', classification: 'caughtlive', category: 'travel' },
    { type: 'video', classification: 'videoartists', category: 'art' },
    { type: 'video', classification: 'videoartists', category: 'comedy' },
    { type: 'video', classification: 'videoartists', category: 'cookingfood' },
    { type: 'video', classification: 'videoartists', category: 'fashion' },
    { type: 'video', classification: 'videoartists', category: 'gaming' },
    { type: 'video', classification: 'videoartists', category: 'musicvideos' },
    { type: 'video', classification: 'videoartists', category: 'photography' },
    { type: 'video', classification: 'interviews', category: 'actors' },
    { type: 'video', classification: 'interviews', category: 'athletes' },
    { type: 'video', classification: 'interviews', category: 'business' },
    { type: 'video', classification: 'interviews', category: 'fashion' },
    { type: 'video', classification: 'interviews', category: 'government' },
    { type: 'video', classification: 'interviews', category: 'health' },
    { type: 'video', classification: 'interviews', category: 'personalities' },
    { type: 'video', classification: 'interviews', category: 'sports' },
    { type: 'video', classification: 'interviews', category: 'sciencetechnology' },
    { type: 'video', classification: 'interviews', category: 'travel' },
    // { type: 'video', classification: 'publicity', category: 'autos' },
    // { type: 'video', classification: 'publicity', category: 'businessfinance' },
    // { type: 'video', classification: 'publicity', category: 'comedy' },
    // { type: 'video', classification: 'publicity', category: 'cookingfood' },
    // { type: 'video', classification: 'publicity', category: 'fashion' },
    // { type: 'video', classification: 'publicity', category: 'health' },
    // { type: 'video', classification: 'publicity', category: 'howto' },
    // { type: 'video', classification: 'publicity', category: 'music' },
    // { type: 'video', classification: 'publicity', category: 'sciencefiction' },
    // { type: 'video', classification: 'publicity', category: 'sports' },
    // { type: 'video', classification: 'publicity', category: 'sciencetechnology' },
    // { type: 'video', classification: 'publicity', category: 'theater' },
    // { type: 'video', classification: 'publicity', category: 'travel' },
    { type: 'video', classification: 'opinionscommentary', category: 'autos' },
    { type: 'video', classification: 'opinionscommentary', category: 'businessfinance' },
    { type: 'video', classification: 'opinionscommentary', category: 'fashion' },
    { type: 'video', classification: 'opinionscommentary', category: 'gaming' },
    { type: 'video', classification: 'opinionscommentary', category: 'health' },
    { type: 'video', classification: 'opinionscommentary', category: 'politicsgovernment' },
    { type: 'video', classification: 'opinionscommentary', category: 'sports' },
    { type: 'video', classification: 'opinionscommentary', category: 'productreviews' },
    { type: 'video', classification: 'opinionscommentary', category: 'filmreviews' },
    { type: 'video', classification: 'opinionscommentary', category: 'sciencetechnology' },
    { type: 'video', classification: 'news', category: 'local' },
    { type: 'video', classification: 'news', category: 'state' },
    { type: 'video', classification: 'news', category: 'national' },
    { type: 'video', classification: 'news', category: 'world' },
    { type: 'video', classification: 'news', category: 'art' },
    { type: 'video', classification: 'news', category: 'autos' },
    { type: 'video', classification: 'news', category: 'business' },
    { type: 'video', classification: 'news', category: 'entertainment' },
    { type: 'video', classification: 'news', category: 'fashion' },
    { type: 'video', classification: 'news', category: 'health'  },
    { type: 'video', classification: 'news', category: 'sports' },
    { type: 'video', classification: 'news', category: 'health' },
    { type: 'video', classification: 'news', category: 'politicsgovernment' },
    { type: 'video', classification: 'news', category: 'sciencetechnology' },
    { type: 'video', classification: 'news', category: 'environment' },
    { type: 'video', classification: 'news', category: 'gaming' },
    { type: 'video', classification: 'news', category: 'disasters' },
    { type: 'video', classification: 'businesstobusiness', category: 'negotiation' },
    { type: 'video', classification: 'instruction', category: 'animalspets' },
    { type: 'video', classification: 'instruction', category: 'artphotography' },
    { type: 'video', classification: 'instruction', category: 'automotiverepairs' },
    { type: 'video', classification: 'instruction', category: 'computers' },
    { type: 'video', classification: 'instruction', category: 'craftshobbies' },
    { type: 'video', classification: 'instruction', category: 'cookingfood' },
    { type: 'video', classification: 'instruction', category: 'english' },
    { type: 'video', classification: 'instruction', category: 'engineering' },
    { type: 'video', classification: 'instruction', category: 'fashion' },
    { type: 'video', classification: 'instruction', category: 'fitnessdiet' },
    { type: 'video', classification: 'instruction', category: 'gardeningfarming' },
    { type: 'video', classification: 'instruction', category: 'geography' },
    { type: 'video', classification: 'instruction', category: 'health' },
    { type: 'video', classification: 'instruction', category: 'history' },
    { type: 'video', classification: 'instruction', category: 'homerepairs' },
    { type: 'video', classification: 'instruction', category: 'languages' },
    { type: 'video', classification: 'instruction', category: 'mathematics' },
    { type: 'video', classification: 'instruction', category: 'medical' },
    { type: 'video', classification: 'instruction', category: 'parenting' },
    { type: 'video', classification: 'instruction', category: 'science' },
    { type: 'video', classification: 'instruction', category: 'selfhelp' },
    { type: 'video', classification: 'instruction', category: 'testpreparation' },
    { type: 'audio', classification: 'music', category: 'alternative' },
    { type: 'audio', classification: 'music', category: 'blues' },
    { type: 'audio', classification: 'music', category: 'children' },
    { type: 'audio', classification: 'music', category: 'christiangospel' },
    { type: 'audio', classification: 'music', category: 'classical' },
    { type: 'audio', classification: 'music', category: 'country' },
    { type: 'audio', classification: 'music', category: 'danceelectronic' },
    { type: 'audio', classification: 'music', category: 'folk' },
    { type: 'audio', classification: 'music', category: 'hiphoprap' },
    { type: 'audio', classification: 'music', category: 'holiday' },
    { type: 'audio', classification: 'music', category: 'jazz' },
    { type: 'audio', classification: 'music', category: 'latin' },
    { type: 'audio', classification: 'music', category: 'metal' },
    { type: 'audio', classification: 'music', category: 'newage' },
    { type: 'audio', classification: 'music', category: 'pop' },
    { type: 'audio', classification: 'music', category: 'rb' },
    { type: 'audio', classification: 'music', category: 'reggae' },
    { type: 'audio', classification: 'music', category: 'rock' },
    { type: 'audio', classification: 'music', category: 'soul' },
    { type: 'audio', classification: 'music', category: 'soundtracks' },
    { type: 'audio', classification: 'podcasts', category: 'arts' },
    { type: 'audio', classification: 'podcasts', category: 'business' },
    { type: 'audio', classification: 'podcasts', category: 'comedy' },
    { type: 'audio', classification: 'podcasts', category: 'culture' },
    { type: 'audio', classification: 'podcasts', category: 'government' },
    { type: 'audio', classification: 'podcasts', category: 'health' },
    { type: 'audio', classification: 'podcasts', category: 'religion' },
    { type: 'audio', classification: 'podcasts', category: 'sciencetechnology' },
    { type: 'audio', classification: 'podcasts', category: 'society' },
    { type: 'audio', classification: 'podcasts', category: 'sports' },
    { type: 'audio', classification: 'podcasts', category: 'tvfilm' },
    { type: 'audio', classification: 'podcasts', category: 'travel' },
    // { type: 'audio', classification: 'audiobooks', category: 'arts' },
    // { type: 'audio', classification: 'audiobooks', category: 'biographiesmemoirs' },
    // { type: 'audio', classification: 'audiobooks', category: 'business' },
    // { type: 'audio', classification: 'audiobooks', category: 'children' },
    // { type: 'audio', classification: 'audiobooks', category: 'cookbooksfoodwine' },
    // { type: 'audio', classification: 'audiobooks', category: 'history' },
    // { type: 'audio', classification: 'audiobooks', category: 'humor' },
    // { type: 'audio', classification: 'audiobooks', category: 'literaturefiction' },
    // { type: 'audio', classification: 'audiobooks', category: 'mystery' },
    // { type: 'audio', classification: 'audiobooks', category: 'nonfiction' },
    // { type: 'audio', classification: 'audiobooks', category: 'romance' },
    // { type: 'audio', classification: 'audiobooks', category: 'science' },
    // { type: 'audio', classification: 'audiobooks', category: 'sciencefiction' },
    // { type: 'audio', classification: 'audiobooks', category: 'travel' },
    { type: 'audio', classification: 'instructioneducation', category: 'artphotography' },
    { type: 'audio', classification: 'instructioneducation', category: 'automotiverepairs' },
    { type: 'audio', classification: 'instructioneducation', category: 'computers' },
    { type: 'audio', classification: 'instructioneducation', category: 'craftshobbies' },
    { type: 'audio', classification: 'instructioneducation', category: 'english' },
    { type: 'audio', classification: 'instructioneducation', category: 'engineering' },
    { type: 'audio', classification: 'instructioneducation', category: 'fitnessdiet' },
    { type: 'audio', classification: 'instructioneducation', category: 'gardeningfarming' },
    { type: 'audio', classification: 'instructioneducation', category: 'geography' },
    { type: 'audio', classification: 'instructioneducation', category: 'history' },
    { type: 'audio', classification: 'instructioneducation', category: 'homerepairs' },
    { type: 'audio', classification: 'instructioneducation', category: 'languages' },
    { type: 'audio', classification: 'instructioneducation', category: 'mathematics' },
    { type: 'audio', classification: 'instructioneducation', category: 'medical' },
    { type: 'audio', classification: 'instructioneducation', category: 'science' },
    { type: 'audio', classification: 'instructioneducation', category: 'selfhelp' },
    { type: 'audio', classification: 'instructioneducation', category: 'testpreparation' },
    { type: 'audio', classification: 'news', category: 'local' },
    { type: 'audio', classification: 'news', category: 'state' },
    { type: 'audio', classification: 'news', category: 'national' },
    { type: 'audio', classification: 'news', category: 'world' },
    { type: 'audio', classification: 'news', category: 'art' },
    { type: 'audio', classification: 'news', category: 'autos' },
    { type: 'audio', classification: 'news', category: 'business' },
    { type: 'audio', classification: 'news', category: 'entertainment' },
    { type: 'audio', classification: 'news', category: 'fashion' },
    { type: 'audio', classification: 'news', category: 'health'  },
    { type: 'audio', classification: 'news', category: 'sports' },
    { type: 'audio', classification: 'news', category: 'health' },
    { type: 'audio', classification: 'news', category: 'politicsgovernment' },
    { type: 'audio', classification: 'news', category: 'sciencetechnology' },
    { type: 'audio', classification: 'news', category: 'environment' },
    { type: 'audio', classification: 'news', category: 'gaming' },
    { type: 'audio', classification: 'news', category: 'disasters' },
    { type: 'audio', classification: 'talkshows', category: 'entertainment' },
    { type: 'audio', classification: 'talkshows', category: 'politicsgovernment' },
    { type: 'audio', classification: 'talkshows', category: 'sports' },
    { type: 'text', classification: 'essays', category: 'business' },
    { type: 'text', classification: 'essays', category: 'crimejustice' },
    { type: 'text', classification: 'essays', category: 'culture' },
    { type: 'text', classification: 'essays', category: 'economy' },
    { type: 'text', classification: 'essays', category: 'environment' },
    { type: 'text', classification: 'essays', category: 'food' },
    { type: 'text', classification: 'essays', category: 'foreignpolicy' },
    { type: 'text', classification: 'essays', category: 'government' },
    { type: 'text', classification: 'essays', category: 'health' },
    { type: 'text', classification: 'essays', category: 'history' },
    { type: 'text', classification: 'essays', category: 'housing' },
    { type: 'text', classification: 'essays', category: 'media' },
    { type: 'text', classification: 'essays', category: 'politicsgovernment' },
    { type: 'text', classification: 'essays', category: 'religion' },
    { type: 'text', classification: 'essays', category: 'sciencetechnology' },
    { type: 'text', classification: 'essays', category: 'fiction' },
    { type: 'text', classification: 'essays', category: 'nonfiction' },
    { type: 'text', classification: 'essays', category: 'sports' },
    { type: 'text', classification: 'essays', category: 'taxes' },
    { type: 'text', classification: 'essays', category: 'transportation' },
    { type: 'text', classification: 'essays', category: 'travel' },
    { type: 'text', classification: 'reviews', category: 'arts' },
    { type: 'text', classification: 'reviews', category: 'biographies' },
    { type: 'text', classification: 'reviews', category: 'children' },
    { type: 'text', classification: 'reviews', category: 'comicsgraphicnovels' },
    { type: 'text', classification: 'reviews', category: 'cookbooksfoodwine' },
    { type: 'text', classification: 'reviews', category: 'history' },
    { type: 'text', classification: 'reviews', category: 'literaturefiction' },
    { type: 'text', classification: 'reviews', category: 'mystery' },
    { type: 'text', classification: 'reviews', category: 'fiction' },
    { type: 'text', classification: 'reviews', category: 'nonfiction' },
    { type: 'text', classification: 'reviews', category: 'romance' },
    { type: 'text', classification: 'reviews', category: 'science' },
    { type: 'text', classification: 'reviews', category: 'sciencefiction' },
    { type: 'text', classification: 'reviews', category: 'travel' },
    { type: 'text', classification: 'reviews', category: 'sciencetechnology' },
    { type: 'text', classification: 'instructioneducation', category: 'artphotography' },
    { type: 'text', classification: 'instructioneducation', category: 'automotiverepairs' },
    { type: 'text', classification: 'instructioneducation', category: 'computers' },
    { type: 'text', classification: 'instructioneducation', category: 'cooking' },
    { type: 'text', classification: 'instructioneducation', category: 'craftshobbies' },
    { type: 'text', classification: 'instructioneducation', category: 'english' },
    { type: 'text', classification: 'instructioneducation', category: 'engineering' },
    { type: 'text', classification: 'instructioneducation', category: 'fitnessdiet' },
    { type: 'text', classification: 'instructioneducation', category: 'gardeningfarming' },
    { type: 'text', classification: 'instructioneducation', category: 'geography' },
    { type: 'text', classification: 'instructioneducation', category: 'history' },
    { type: 'text', classification: 'instructioneducation', category: 'homerepairs' },
    { type: 'text', classification: 'instructioneducation', category: 'languages' },
    { type: 'text', classification: 'instructioneducation', category: 'mathematics' },
    { type: 'text', classification: 'instructioneducation', category: 'medical' },
    { type: 'text', classification: 'instructioneducation', category: 'science' },
    { type: 'text', classification: 'instructioneducation', category: 'selfhelp' },
    { type: 'text', classification: 'instructioneducation', category: 'sciencetechnology' },
    { type: 'text', classification: 'instructioneducation', category: 'testpreparation' },
    { type: 'text', classification: 'news', category: 'local' },
    { type: 'text', classification: 'news', category: 'state' },
    { type: 'text', classification: 'news', category: 'national' },
    { type: 'text', classification: 'news', category: 'world' },
    { type: 'text', classification: 'news', category: 'art' },
    { type: 'text', classification: 'news', category: 'autos' },
    { type: 'text', classification: 'news', category: 'business' },
    { type: 'text', classification: 'news', category: 'entertainment' },
    { type: 'text', classification: 'news', category: 'fashion' },
    { type: 'text', classification: 'news', category: 'health'  },
    { type: 'text', classification: 'news', category: 'sports' },
    { type: 'text', classification: 'news', category: 'health' },
    { type: 'text', classification: 'news', category: 'politicsgovernment' },
    { type: 'text', classification: 'news', category: 'sciencetechnology' },
    { type: 'text', classification: 'news', category: 'environment' },
    { type: 'text', classification: 'news', category: 'gaming' },
    { type: 'text', classification: 'news', category: 'disasters' },
    { type: 'text', classification: 'opinionscommentary', category: 'business' },
    { type: 'text', classification: 'opinionscommentary', category: 'crimejustice' },
    { type: 'text', classification: 'opinionscommentary', category: 'culture' },
    { type: 'text', classification: 'opinionscommentary', category: 'economy' },
    { type: 'text', classification: 'opinionscommentary', category: 'environment' },
    { type: 'text', classification: 'opinionscommentary', category: 'food' },
    { type: 'text', classification: 'opinionscommentary', category: 'foreignpolicy' },
    { type: 'text', classification: 'opinionscommentary', category: 'government' },
    { type: 'text', classification: 'opinionscommentary', category: 'health' },
    { type: 'text', classification: 'opinionscommentary', category: 'housing' },
    { type: 'text', classification: 'opinionscommentary', category: 'media' },
    { type: 'text', classification: 'opinionscommentary', category: 'politicsgovernment' },
    { type: 'text', classification: 'opinionscommentary', category: 'religion' },
    { type: 'text', classification: 'opinionscommentary', category: 'sciencetechnology' },
    { type: 'text', classification: 'opinionscommentary', category: 'sports' },
    { type: 'text', classification: 'opinionscommentary', category: 'taxes' },
    { type: 'text', classification: 'opinionscommentary', category: 'transportation' },
    { type: 'text', classification: 'opinionscommentary', category: 'travel' },
    { type: 'text', classification: 'opinionscommentary', category: 'sciencetechnology' }
];
