import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration'
})
export class DurationFormatPipe implements PipeTransform {
  transform(seconds: number) {
    if (!seconds) {
      return '';
    } else {
      let result = '';
      if (seconds > 3600) {
        result = Math.floor(seconds / 3600) + ':';
        seconds = seconds % 3600;
      }
      const minutes = Math.floor(seconds / 60);
      seconds = Math.floor(seconds % 60);
      result = result + `${minutes}:${seconds.toString().padStart(2, '0')}`;

      return result;
    }
  }
}
